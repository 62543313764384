/*
@tailwind base
*/
@import "tailwindcss/base";
@import "@radix-ui/themes/styles.css";

@tailwind components;
@tailwind utilities;

.radix-themes {
  --heading-font-family: var(--font-ttNorms);
  --code-font-family: var(--font-figtree);
  --strong-font-family: var(--font-figtree);
  --em-font-family: var(--font-figtree);
  --quote-font-family: var(--font-figtree);
  --default-font-family: var(--font-figtree) !important;
  --color-background: var(--mauve-1);
}

/* Base font size */
html {
  font-size: 100% !important;
}
body,
html {
  /*overscroll-behavior: none;*/
  background-color: var(--mauve-1) !important;
}
/*body {*/
/*  position: absolute;*/
/*}*/
/*@media (min-width: 1024px) {*/
/*  html {*/
/*    font-size: 1rem !important; !* 21.33px equivalent *!*/
/*  }*/
/*}*/
/*!* Large screens *!*/
/*@media (min-width: 900px) {*/
/*  html {*/
/*    font-size: 1rem !important; !* 21.33px equivalent *!*/
/*  }*/
/*}*/

@media screen and (max-device-width: 100000px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}
body {
  padding: 0;
  margin: 0;
  background-color: rgb(225, 255, 0);
}
html {
  padding: 0;
  margin: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  background-color: green;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
}

.rt-ScrollAreaViewport > * {
  overscroll-behavior: none;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 0 0% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 3.9%;
    --primary: 0 0% 9%;
    --primary-foreground: 0 0% 98%;
    --secondary: 0 0% 96.1%;
    --secondary-foreground: 0 0% 9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 45.1%;
    --accent: 0 0% 96.1%;
    --accent-foreground: 0 0% 9%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 89.8%;
    --input: 0 0% 89.8%;
    --ring: 0 0% 3.9%;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
    --radius: 0.5rem;
  }
  .dark {
    --background: 0 0% 3.9%;
    --foreground: 0 0% 98%;
    --card: 0 0% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 0 0% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 0 0% 9%;
    --secondary: 0 0% 14.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 14.9%;
    --muted-foreground: 0 0% 63.9%;
    --accent: 0 0% 14.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 0 0% 14.9%;
    --input: 0 0% 14.9%;
    --ring: 0 0% 83.1%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
